<app-splashscreen *ngIf="isInitializing"></app-splashscreen>
<p-confirmDialog styleClass="w-[70vh]"/><p-confirmDialog #noCompanyDialog />
<div class="flex flex-col min-h-screen" *ngIf="hasCompanies">
    <!-- Top bar -->
    <div class="bg-bunker-500 shadow-sm flex p-1 justify-between items-center border-b border-gray-200 dark:border-gray-800 z-[40] print:hidden">
      <div class="hidden lg:flex items-center gap-4 mx-2">
        <app-app-panel></app-app-panel>
        <img src="../assets/img/logo_large.svg" class="mx-auto max-h-10" alt="BDO mobile" />
        <p-chip label="ESG" severity="info" class="text-xs"></p-chip>
      </div>
      <!-- Menu button for mobile -->
      <button (click)="sidebarVisible = true" class="menu-button lg:hidden">
        <i class="pi pi-bars text-xl"></i>
      </button>
      <!-- Logo centered for mobile, hidden on desktop -->
      <div class="flex-grow lg:flex-grow-0 lg:hidden text-center">
        <img src="../assets/img/logo_sygnet.svg" class="w-16 h-16 mx-auto" alt="BDO mobile" />
      </div>
      <!-- Spacer to push icons to the right on desktop, hidden on mobile -->
      <div class="hidden lg:flex flex-grow"></div>
      <!-- Icons for settings, notifications, and night mode (desktop only) -->
      <div class="flex items-center">
        <p-button pRipple type="button" [icon]="themeService.isDarkMode ? 'pi pi-sun' : 'pi pi-moon'" class="mx-2" (onClick)="toggleNightMode()" [pTooltip]="themeService.isDarkMode ? 'Zmień na tryb jasny' : 'Zmień na tryb ciemny'" tooltipPosition="top" [text]="true"></p-button>
        <p-button pRipple type="button" icon="pi pi-sign-out" styleClass="lg:hidden" severity="danger" (onClick)="logout()" pTooltip="Wyloguj" tooltipPosition="top" [text]="true"></p-button>
      </div>
      <app-language-button (refreshData)="handleRefreshData()"></app-language-button>
      <app-header-profile></app-header-profile>
    </div>
  
    <!-- Main content with sidebar -->
    <div class="flex-grow">
      <div class="flex overflow-hidden">
        <!-- Permanent sidebar for desktop -->
        <div class="hidden lg:flex bg-gray-100 dark:bg-gray-900 shadow-lg z-[30] flex flex-col relative sidebar border-white dark:border-gray-800 border-r min-h-[80vh] print:hidden" 
             [ngClass]="{ 'transition-width duration-300 ease-in-out': !isResizing }"
             [ngStyle]="{'width.px': sidebarWidth}"
             (mouseleave)="onMouseLeaveSidebar()"
             (mouseenter)="temporarilyShowSidebar()" >
          <!-- Sidebar Menu List -->
          <div 
            [ngClass]="{ 'transition-width duration-300 ease-in-out': !isResizing }"
            [ngStyle]="{'width.px': sidebarWidth}"
                 class="flex flex-col gap-4 text-gray-700 dark:text-gray-200 text-lg bg-gray-100 border-white dark:border-gray-800 dark:bg-gray-900 border-r min-h-[70vh] pb-8">
              <app-company-select class="px-2 mt-2" *ngIf="!isCollapsed || sidebarWidth === MAX_WIDTH"></app-company-select>
              <app-menu class="mb-4" *ngIf="!isCollapsed || sidebarWidth === MAX_WIDTH" [items]="menuItems"></app-menu>
          </div>
          
          <!-- Toggle Button -->
          <button *ngIf="showToggleButton" 
            class="fixed  top-1/2 z-[9999] left-[100px] p-1 w-8 h-8 rounded-full border border-gray-200 dark:border-slate-700 bg-white shadow-lg hover:bg-slate-500 dark:bg-gray-800 dark:hover:bg-gray-900 text-slate-600 hover:text-white dark:hover:text-slate-400 cursor-pointer transition-width duration-300 ease-in-out"
            [ngStyle]="{'left.px': sidebarWidth - 15}"
            (click)="toggleSidebar()">
            <i class="pi pi-chevron-left" *ngIf="!isCollapsed"></i>
            <i class="pi pi-chevron-right" *ngIf="isCollapsed"></i>
          </button>
  

          <!-- Resizer Handle -->
          <div class="absolute top-0 w-0.5 hover:bg-slate-400 right-0 bottom-0 cursor-col-resize transition-width duration-500 ease-in-out"
          (mousedown)="startResizing($event)"
          (mouseenter)="showToggleButtonFunction()">
          </div>
        </div>
    
        <!-- Main content area -->
        <div class="flex-grow flex flex-col overflow-auto">
          <div class="p-4">
            <router-outlet></router-outlet>
          </div>
          <p-button pRipple *ngIf="scrolled" 
            icon="pi pi-arrow-up" 
            class="fixed bottom-4 justify-center right-4 z-50 w-10 h-10 rounded-full border border-gray-200 dark:border-slate-700 bg-white shadow-lg hover:bg-slate-500 dark:bg-gray-800 dark:hover:bg-gray-900 text-slate-600 hover:text-white dark:hover:text-slate-400 cursor-pointer transition-width duration-500 ease-in-out" 
            (onClick)="scrollToTop()" 
            [ngClass]="{'block': scrolled, 'hidden': !scrolled}">
          </p-button>
        </div>
      </div>
    </div>
    
    <app-footer class="print:hidden"></app-footer>
  </div>
  
  <!-- Sliding sidebar for mobile -->
  <p-sidebar [(visible)]="sidebarVisible" [baseZIndex]="10000" [modal]="isHandset$ | async" class="w-80" [showCloseIcon]="isHandset$ | async" class="print:hidden">
    <app-company-select></app-company-select>
    <app-menu [items]="menuItems"></app-menu>
    <ng-template pTemplate="header">
      <div class="flex gap-2 justify-between items-center">
        <img src="../assets/img/logo_sygnet.svg" class="w-16 h-16" alt="BDO mobile" />
        <p-chip label="ESG" severity="info" class="text-3xl"></p-chip>
      </div>
    </ng-template>
  </p-sidebar>
  